import React from "react";
import { Link } from "gatsby";
import { css, useColorMode, Styled } from "theme-ui";
import Switch from "./switch";
import Bio from "./bio";
import sun from "../../content/assets/sun.png";
import moon from "../../content/assets/moon.png";

const rootPath = `${__PATH_PREFIX__}/`;

const Title = ({ children, location }) => {
  if (location.pathname === rootPath) {
    return (
      <Styled.h1
        css={css({
          my: 0,
          fontSize: 5,
          fontFamily: "Paytone One, system-ui, sans-serif",
          fontWeight: 100
        })}
      >
        <Styled.a
          as={Link}
          css={{
            color: "inherit",
            boxShadow: "none",
            textDecoration: "none"
          }}
          to="/"
        >
          {children}
        </Styled.a>
      </Styled.h1>
    );
  }
  return (
    <Styled.h3
      as="p"
      css={css({
        my: 0,
        fontFamily: "Paytone One, system-ui, sans-serif",
        fontWeight: 100
      })}
    >
      <Styled.a
        as={Link}
        css={css({
          boxShadow: "none",
          textDecoration: "none",
          color: "primary"
        })}
        to="/"
      >
        {children}
      </Styled.a>
    </Styled.h3>
  );
};

const checkedIcon = (
  <img
    alt="moon indicating dark mode"
    src={moon}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: "none",
      margin: 4
    }}
  />
);

const uncheckedIcon = (
  <img
    alt="sun indicating light mode"
    src={sun}
    width="16"
    height="16"
    role="presentation"
    css={{
      pointerEvents: "none",
      margin: 4
    }}
  />
);

export default ({ children, title, ...props }) => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === "dark";
  const toggleColorMode = e => {
    setColorMode(isDark ? "light" : "dark");
  };

  return (
    <header>
      <div
        css={css({
          maxWidth: "container",
          mx: "auto",
          px: 3,
          pt: 4
        })}
      >
        <div
          css={css({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            mb: 3
          })}
        >
          <Title {...props}>{title}</Title>
          {children}
          <Switch
            aria-label="Toggle dark mode"
            css={css({
              bg: "highlight"
            })}
            checkedIcon={checkedIcon}
            uncheckedIcon={uncheckedIcon}
            checked={isDark}
            onChange={toggleColorMode}
          />
        </div>
        {props.location.pathname === rootPath && <Bio />}
      </div>
    </header>
  );
};
